import http from '../clients/Axios'

export default class LaporanApotekerService {
  async exportLaporanPenjualanGolongan(
    periode_from,
    periode_to,
    golongan_id,
    type
  ) {
    return http({
      url: `/report/apoteker/penjualan/golongan/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanReturGolonganPenjualan(
    periode_from,
    periode_to,
    golongan_id,
    type
  ) {
    return http({
      url: `/report/apoteker/retur/golongan/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanReturGolonganPembelian(
    periode_from,
    periode_to,
    golongan_id,
    type
  ) {
    return http({
      url: `/report/apoteker/retur/golongan/pembelian/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanReturPrekursorPrint(periode_from, periode_to, golongan_id) {
    return http({
      url: `/report/apoteker/laporan/retur/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanReturPrekursorExport(periode_from, periode_to, golongan_id) {
    return http({
      url: `/report/apoteker/laporan/retur/export?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanTriwulanBatch(triwulan, pabrik_id) {
    return http({
      url: `/report/apoteker/triwulan/batch/print?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanTriwulanBarang(triwulan, pabrik_id, tahun) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/apoteker/triwulan/barang/export?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}&filter[tahun]=${tahun}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async printLaporanTriwulanBarang(triwulan, pabrik_id, tahun) {
    return http({
      url: `/report/apoteker/triwulan/barang/print?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}&filter[tahun]=${tahun}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanEReport(periode_from, periode_to, golongan_id) {
    return http({
      url: `/report/apoteker/laporan/ereport/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanEReportExport(periode_from, periode_to, golongan_id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/apoteker/laporan/ereport/export?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdie(periode_from, periode_to, golongan_id, type) {
    return http({
      url: `/report/apoteker/laporan/siodie/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdieExport(periode_from, periode_to, golongan_id, type) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/apoteker/laporan/siodie/export?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdieTriwulan(periode, golongan_id, type, tahun) {
    return http({
      url: `/report/apoteker/laporan/siodie/triwulan/print?filter[periode]=${periode}&filter[golongan_id]=${golongan_id}&filter[type]=${type}&filter[tahun]=${tahun}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdieExportTriwulan(periode, golongan_id, type, tahun) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/apoteker/laporan/siodie/triwulan/export?filter[periode]=${periode}&filter[golongan_id]=${golongan_id}&filter[type]=${type}&filter[tahun]=${tahun}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
